import { A, useNavigate } from '@solidjs/router';
import { Show } from 'solid-js';
import IconEmail from '~/assets/images/home/email.svg';
import IconFacebook from '~/assets/images/home/facebook.svg';
import IconInstagram from '~/assets/images/home/instagram.svg';
import IconLinkedin from '~/assets/images/home/linkedin.svg';
import IconPhone from '~/assets/images/home/phone.svg';
import IconTk from '~/assets/images/home/tk.svg';
import IconTwitter from '~/assets/images/home/twitter.svg';
import IconYoutube from '~/assets/images/home/youtube.svg';
import { EMAIL, PHONE, FOLLOW_US, SUBMIT_A_RENTAL_APPLICATION, TENANT_SIGN_IN } from '~/assets/strings';
import { Skeleton } from '~/components/common/Skeleton';
import { Button } from '~/components/common/buttons';
import { LocalizationButton } from '~/components/layouts/Navigation/localization/LocalizationButton';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { PresentableCompayStyle } from '~/presenters/CompanyStylePresenter';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { CreateRentalApplicationUseCase } from '~/use-cases/rental-applications/createRentalApplicationUseCase';
import { Menu } from './Menu';

interface HeaderProps {
  companyStyling: PresentableCompayStyle | undefined;
}

export const Header = (props: HeaderProps) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: createRentalApplication, didSucceed, isLoading } = useUseCase(CreateRentalApplicationUseCase);

  const onCreateRentalApplication = async () => {
    await createRentalApplication();
    if (didSucceed()) {
      navigate(`${presentableRentalApplication()?.url}/applicant-information`);
    }
  };

  const navigateToTenantSignIn = () => {
    window.location.href = `/tenants/auth/signin`;
  };

  return (
    <div class="fixed top-0 z-50 flex w-full flex-col items-center bg-white">
      <div class="flex h-[54px]  w-full items-center justify-center border-b border-b-[#D5D9E080]">
        <div class="flex w-max-container-width justify-between">
          <div class="flex items-center">
            <div class="flex items-center gap-2">
              <img src={IconEmail} alt="email" />
              <span class="text-sm text-[#33333366]">{t(EMAIL)}</span>
              <Show when={props?.companyStyling?.email} fallback={<Skeleton class="w-[180px]" />}>
                <span class="text-sm text-[#333333]">{props?.companyStyling?.email}</span>
              </Show>
            </div>
            <div class=" mx-5 h-[20px] w-px bg-[#3333331A]" />
            <div class="flex items-center gap-2">
              <img src={IconPhone} alt="phone" />
              <span class="text-sm text-[#33333366]">{t(PHONE)}</span>
              <Show when={props?.companyStyling?.phoneNumber} fallback={<Skeleton class="w-[180px]" />}>
                <span class="text-sm text-[#333333]">{props?.companyStyling?.phoneNumber}</span>
              </Show>
            </div>
          </div>
          <div class="flex items-center gap-[20px]">
            <div class="text-sm text-[#33333366]">{t(FOLLOW_US)}:</div>
            <div class="flex items-center gap-6">
              <Show when={props?.companyStyling?.facebook}>
                <A href={props?.companyStyling?.facebook ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img class="size-[14px]" src={IconFacebook} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.instagram}>
                <A href={props?.companyStyling?.instagram ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconInstagram} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.linkedIn}>
                <A href={props?.companyStyling?.linkedIn ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconLinkedin} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.twitter}>
                <A href={props?.companyStyling?.twitter ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconTwitter} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.youtube}>
                <A href={props?.companyStyling?.youtube ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconYoutube} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.tikTok}>
                <A href={props?.companyStyling?.tikTok ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconTk} />
                </A>
              </Show>
            </div>
          </div>
        </div>
      </div>

      <div class="flex h-header-height w-max-container-width items-center   justify-between">
        <div class="flex items-center gap-10">
          <Show when={props?.companyStyling?.logo} fallback={<Skeleton class="size-[50px]" />}>
            <A href="/company-website">
              <img src={props?.companyStyling?.logo} class="size-[50px]" alt="logo" />
            </A>
          </Show>
          <Menu />
        </div>
        <div class="flex items-center gap-3">
          <Button onClick={navigateToTenantSignIn} class="bg-white text-primary-color" href="/">
            {t(TENANT_SIGN_IN)}
          </Button>
          <Button loading={isLoading()} onClick={onCreateRentalApplication}>
            {t(SUBMIT_A_RENTAL_APPLICATION)}
          </Button>
          <LocalizationButton />
        </div>
      </div>
    </div>
  );
};
